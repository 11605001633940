import globals from "../globals";
import Storage from "./storage";

const APIBASE = globals.REACT_APP_APIBASE;
const ENDERECO = globals.REACT_APP_ENDERECO;
const CATALOGO = globals.REACT_APP_CATALOGO;
function getToken() {
  let userCredencial = Storage.get("USUARIO")?.token;
  return userCredencial;
}

const ApiServices = {
  apiLogin: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      var captcha = Storage.get("captcha") || null;

      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: { "content-type": "application/json", captcha: captcha,},
        body: JSON.stringify(obj),
      });
    } else {
    }
  },

  apiGet: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${APIBASE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },

  apiPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiPut: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
      // .then((res) => res);
    } else {
    }
  },
  apiPatch: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiDel: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res);
    } else {
    }
  },
  apiGetEndereco: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${ENDERECO}${parameter}`, {
        method: "GET",
      }).then((res) => res.json());
    } else {
    }
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////        CATALOGO          ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  apiGetCatalogo: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${CATALOGO}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }).then((res) => res.json());
    } else {
    }
  },
  apiPostCatalogo: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${CATALOGO}${parameter}`, {
        mode: 'cors',
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
       body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////        CATALOGO          ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },

  
  apiUploadPDF: (parameter = undefined, file = undefined) => {
    if (parameter && file) {
      const formData = new FormData();
      formData.append("file", file);

      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: formData,
      }).then((res) => res.json());
    } else {
      // Tratar o caso em que nem o parâmetro nem o arquivo são fornecidos
      return Promise.reject("Parâmetro e/ou arquivo não fornecidos");
    }
  },

  apiDownloadXlsxGET: (parameter = undefined, nomeArquivo = "relatorio") => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "GET",
        headers: {
          "content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          // Retorna o corpo da resposta como um Blob (objeto binário)
          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = nomeArquivo + ".xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
    }
  },
};

export default ApiServices;
